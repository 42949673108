/* Component Dependencies */
var hsiaTopBarTemplate = require('templates/hsiaTopBar.hbs');
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');
var Popup = require('libs/popup');

AriesComponent.create({
  type: 'hsiaTopBar',
  template: {
    'hsiaTopBar': hsiaTopBarTemplate
  },
  bindEvents: function() {
    var _self = this;
    _self.registerPopup();
  },
  registerPopup: function() {
    this.learnMorePopup = new Popup({
      selector: '.tile-hsia-top-bar .mi-popover',
      sourceBlock: '.modal-content',
      addClassOnmfpWrap: true
    });
    this.learnMorePopup.register();
  }
});
